import Swiper, {Pagination} from 'swiper';
import 'swiper/css/bundle';

export function InterviewSlider() {
    const parentElements = document.querySelectorAll('.m-interview-slider');
    parentElements.forEach(function(elemnt){
        const sliperElement = elemnt.querySelector(".swiper");
        const swiper = new Swiper( sliperElement, {
            modules: [Pagination],
            loop: true,
            speed: 600,
            allowTouchMove: true,
            centeredSlides: true,
            slidesPerView: "auto",
            spaceBetween: 20,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: "auto",
                    spaceBetween: 40,
                },
            }
        });

        const prevElement = elemnt.querySelector(".m-interview-slider__prev");
        const nextElement = elemnt.querySelector(".m-interview-slider__next");
        prevElement.addEventListener('click', (event)=>{
            swiper.slidePrev();
        })
        nextElement.addEventListener('click', (event)=>{
            swiper.slideNext();
        })
    })
}

