import $ from 'jquery';

/**
 * data-type="scroll" なアンカーリンクに対してスクロールイベントを付与
 */

export function ScrollLink() {
    $(document).on('click', 'a[data-type=scroll]', function() {
        const href = $(this).attr("href");
        const index = href.indexOf("#");
        const target = $(href.slice(index));
        if(target.length){
            const position = target.offset().top;
            $('body,html').animate({scrollTop:position}, 400, 'swing');
            $('body').removeClass('is-naviOpened');
            return false;
        }
    });
}