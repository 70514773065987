import $ from 'jquery';

/**
 * m-faq-list
 */

export function CourseAccordionBox() {
    const parentElements = document.querySelectorAll('.c-course-accordionbox');
    parentElements.forEach(function(element){
        const panret = $(element);
        const button = panret.find('.c-course-accordionbox__label');
        button.on('click', (event)=>{
            const target = panret.find('.c-course-accordionbox__inner');
            if( panret.hasClass('is-active') ){
                target.slideUp();
                panret.removeClass('is-active');
            }else{
                target.slideDown();
                panret.addClass('is-active');
            }
        })
    })
}
