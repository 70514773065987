import Swiper, {Autoplay, EffectFade} from 'swiper';
import 'swiper/css/bundle';

export function MvSlider() {
    const parentElements = document.querySelectorAll('.t-top-mv__slider');
    parentElements.forEach(function(elemnt){
        const sliperElement = elemnt.querySelector(".swiper");
        const swiper = new Swiper( sliperElement, {
            modules: [Autoplay, EffectFade],
            loop: true,
            speed: 2000,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            allowTouchMove: false,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
            }, 
        });
    })
}

