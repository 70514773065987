import $ from 'jquery';

export function GmenuListItem() {
    $(document).on('click', '.c-gmenu-list__item .__row-button', function() {
        const parent = $(this).parent();
        const target = parent.next('ul');
        if( $(this).hasClass('is-active') ){
            $(this).removeClass('is-active');
            target.slideUp();
        }else{
            $(this).addClass('is-active');
            target.slideDown();
        }
    });
}

