import $ from 'jquery';

/**
 * 外部ドメインへのアンカーリンクを別ウィンドウで開くように
 */

export function setupExternalLink() {
    let elm = $("a[href^='http://'],a[href^='https://']");
    let exarr = [
        "localhost",
        "192.168.",
        "127.0.0.1"
    ];
    exarr.push(location.hostname);
    elm.each(function(){
        if(typeof $(this).attr('target') != 'undefined') return;
        for(var i=0;i<exarr.length;++i){
            if(!$(this).attr('href').indexOf("http://"+exarr[i])) return;
            if(!$(this).attr('href').indexOf("https://"+exarr[i])) return;
        }
        $(this).attr('target','_blank');
    });
}
