import { viewportObserver } from './viewportObserver.js';
import { setupExternalLink } from './setupExternalLink.js';
import { setupNaviEvent } from './setupNaviEvent.js';
import { setupWindowScrollEvent } from './setupWindowScrollEvent.js';


import { TopPage } from './pages/TopPage.js';

import { MvSlider } from './component/MvSlider.js';
import { TeacherSlider } from './component/TeacherSlider.js';
import { InterviewSlider } from './component/InterviewSlider.js';
import { FaqItem } from './component/FaqItem.js';
import { CourseAccordionBox } from './component/CourseAccordionBox.js';
import { GmenuListItem } from './component/GmenuListItem.js';
import { SpScroller } from './component/SpScroller.js';
import { TeacherBox } from './component/TeacherBox.js';

import { ScrollLink } from './library/ScrollLink.js';
import { DomWidthWatch } from './library/DomWidthWatch.js';
import { Draggable } from './library/Draggable.js';
import { Loader } from './library/Loader.js';

// import _ from 'lodash';
// import $ from 'jquery';

// window._ = _;
// window.$ = window.jQuery = $;


/**
 * page event
 */

/**
 * library
 */
ScrollLink();
Draggable();
DomWidthWatch([
    'body',
    '.c-interview-card',
    //'.t-top-mv',
    //'.m-reason-list'
]);
Loader();

/**
 * components
 */

MvSlider();
TeacherSlider();
InterviewSlider();
FaqItem();
CourseAccordionBox();
GmenuListItem();
SpScroller();
TeacherBox();

/**
 * page
 */
TopPage();


// ハンバーガー
setupNaviEvent();

// スクロールイベント付与
setupWindowScrollEvent();

// 外部リンクへ_blank付与
setupExternalLink();


/**
 * スクリーンサイズ監視
 */
window.viewportObserver = new viewportObserver();