import $ from 'jquery';

/**
 * m-faq-list
 */

export function SpScroller() {
    $(window).on('resize', watch);
    watch();
}


function watch() {
    $('.m-spScroller').each(function(index, element){
        const inner = $(element).find('.m-spScroller__inner').get(0);
        console.log(inner.scrollWidth, element.clientWidth);
        if (inner.scrollWidth <= element.clientWidth) {
            $(this).removeClass('is-overflow');
        } else {
            $(this).addClass('is-overflow');
        }
    })
}