import $ from 'jquery';

/**
 * ハンバーガーメニューのクリックイベント
 */

export function setupNaviEvent() {
    $('.o-header-naviButton').on('click', function(){
        $('body').toggleClass('is-naviOpened');
    });
}