import $ from 'jquery';

/**
 * ウィンドウスクロール時のイベント付与
 * bodyへのクラス付け外し
 */

 export function setupWindowScrollEvent() {
    $(function(){
        scrolling();
    });
    $(window).on('scroll', scrolling);
}

function scrolling(){
    var t = window.pageYOffset;
    var wh = document.documentElement.clientHeight;
    var dh = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
    );
    
    if( t<20 ){
        //top
        $('body').addClass('is-top');
        $('body').removeClass('is-middle');
        $('body').removeClass('is-bottom');
    }else if( t>(dh-wh-20) ){
        //bottom
        $('body').addClass('is-bottom');
        $('body').removeClass('is-top');
        $('body').removeClass('is-middle');
    }else{
        //middle
        $('body').addClass('is-middle');
        $('body').removeClass('is-top');
        $('body').removeClass('is-bottom');
    }
}
