import $ from 'jquery';

/**
 * m-faq-list
 */

export function FaqItem() {
    $(document).on('click', '.m-faq-list__item', function(){
        const target = $(this).find('.m-faq-list__item-answer');
        if( $(this).hasClass('is-active') ){
            target.slideUp();
            $(this).removeClass('is-active');
        }else{
            target.slideDown();
            $(this).addClass('is-active');
        }
    })
}
