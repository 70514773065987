import Swiper, {Autoplay, EffectFade, Pagination} from 'swiper';
import 'swiper/css/bundle';

export function TeacherSlider() {
    const parentElements = document.querySelectorAll('.m-teacher-slider');
    parentElements.forEach(function(elemnt){
        const sliperElement = elemnt.querySelector(".swiper");
        const swiper = new Swiper( sliperElement, {
            modules: [Autoplay, EffectFade, Pagination],
            loop: true,
            speed: 2000,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            allowTouchMove: false,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });
    })
}

