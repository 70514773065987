import $ from 'jquery';
import { gsap, Power4 } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);
window.gsap = gsap;

/**
 * m-faq-list
 */

export function TopPage() {
    if( $('body.p-top').length ){
        setup();
    }
}

function setup(){
    $('.m-feature-overview__item').each( function(index, element){
        gsap.to( $(this), { x: 0, y: '+=30%', opacity: 0 });
        gsap.timeline({
            scrollTrigger: {
                trigger: $(this).parent(),
                start: "top bottom-=100px",
                markers: false,
                once: true,
                onEnter: ()=>{
                    gsap.fromTo( $(this), { x: 0, y: '+=30%', opacity: 0 }, { duration: 1.0, delay: index*0.2, x: 0, y: 0, opacity: 1 });
                },
            }
        });
    });
    $('#sb_instagram .sbi_item').each( function(index, element){
        gsap.to( $(this), { x: 0, y: '+=30%', opacity: 0 });
        gsap.timeline({
            scrollTrigger: {
                trigger: $(this).parent(),
                start: "top bottom-=100px",
                markers: false,
                once: true,
                onEnter: ()=>{
                    gsap.fromTo( $(this), { x: 0, y: '+=30%', opacity: 0 }, { duration: 1.0, delay: index*0.2, x: 0, y: 0, opacity: 1 });
                },
            }
        });
    });
}