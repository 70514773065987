import $ from 'jquery';

/**
 * m-faq-list
 */

export function TeacherBox() {
    $('.m-teacher-box').each(function(){
        const parent = $(this);
        ajust( parent.get(0) );
        parent.find('.m-teacher-box__tab-item[data-index]').on('click', function(){
            const index = $(this).data('index');
            parent.find('.m-teacher-box__tab-item.is-active').removeClass('is-active');
            $(this).addClass('is-active');
            
            parent.find('.m-teacher-box__main-item.is-active').removeClass('is-active');
            parent.find('.m-teacher-box__main-item[data-index='+index+']').addClass('is-active');

            //高さajust
            ajust( parent.get(0) );

            //scroll
            const position = $('#view').offset().top;
            $('body,html').animate({scrollTop:position}, 400, 'swing');
        });
    });
}

function ajust(element){
    const wrap = $(element).find('.m-teacher-box__main');
    const target = $(element).find('.m-teacher-box__main-item.is-active');
    const target_height = target.get(0).clientHeight - 1;

    wrap.css('height', target_height);
}