import $ from 'jquery';

/**
 * ウィンドウサイズが変更されるたびに呼ばれ、ブレイクポイントを調べる
 */

export function viewportObserver() {
    this.$window = $( window );
    this.$document = $( 'html, body' );

    this.isMobileSE = null;
    this.isMobile = null;
    this.isTablet = null;
    this.isDesktop = null;
    this.isDesktopWide = null;
    
    this.PreviousMobileSE = null;
    this.PreviousMobile = null;
    this.PreviousTablet = null;
    this.PreviousDesktop = null;
    this.PreviousDesktopWide = null;

    this.MobileSEQuery = 'screen and (max-width: 374px)';
    this.MobileQuery = 'screen and (min-width: 375px) and (max-width: 749px)';
    this.TabletQuery = 'screen and (min-width: 750px) and (max-width: 959px)';
    this.DesktopQuery = 'screen and (min-width: 960px) and (max-width: 1279px)';
    this.DesktopWideQuery = 'screen and (min-width: 1280px)';

    this.events = {
        Change: 'Change',
        MobileSE: 'MobileSE',
        Mobile: 'Mobile',
        Tablet: 'Tablet',
        Desktop: 'Desktop',
        DesktopWide: 'DesktopWide',
    };

    this.setup();

    window.addEventListener('resize', this.onResize.bind(this));
};


viewportObserver.prototype.setup = function() {
    this.isMobileSE = false;
    this.isMobile = false;
    this.isTablet = false;
    this.isDesktop = false;
    this.isDesktopWide = false;

    this.PreviousMobileSE = false;
    this.PreviousMobile = false;
    this.PreviousTablet = false;
    this.PreviousDesktop = false;
    this.PreviousDesktopWide = false;

    if(window.matchMedia( this.MobileSEQuery ).matches){
        this.isMobileSE = true;
    }else if(window.matchMedia( this.MobileQuery ).matches){
        this.isMobile = true;
    }else if(window.matchMedia( this.TabletQuery ).matches){
        this.isTablet = true;
    }else if(window.matchMedia( this.DesktopQuery ).matches){
        this.isDesktop = true;
    }else if(window.matchMedia( this.DesktopWideQuery ).matches){
        this.isDesktopWide = true;
    }
};


viewportObserver.prototype.onResize = function() {
    //前回の値の保存
    this.PreviousMobileSE = this.isMobileSE;
    this.PreviousMobile = this.isMobile;
    this.PreviousTablet = this.isTablet;
    this.PreviousDesktop = this.isDesktop;
    this.PreviousDesktopWide = this.isDesktopWide;

    if(window.matchMedia( this.MobileSEQuery ).matches){
        if(!this.isMobileSE){
            //this.isMobileSE = false;
            this.isMobile = false;
            this.isTablet = false;
            this.isDesktop = false;
            this.isDesktopWide = false;

            this.isMobileSE = true;
            this.$window.trigger(this.events.Change);
            this.$window.trigger(this.events.MobileSE);
        }
    }else if(window.matchMedia( this.MobileQuery ).matches){
        if(!this.isMobile){
            this.isMobileSE = false;
            //this.isMobile = false;
            this.isTablet = false;
            this.isDesktop = false;
            this.isDesktopWide = false;

            this.isMobile = true;
            this.$window.trigger( this.events.Change );
            this.$window.trigger( this.events.Mobile );
        }
    }else if(window.matchMedia( this.TabletQuery ).matches){
        if(!this.isTablet){
            this.isMobileSE = false;
            this.isMobile = false;
            //this.isTablet = false;
            this.isDesktop = false;
            this.isDesktopWide = false;
            
            this.isTablet = true;
            this.$window.trigger(this.events.Change);
            this.$window.trigger(this.events.Tablet);
        }
    }else if(window.matchMedia( this.DesktopQuery ).matches){
        if(!this.isDesktop){
            this.isMobileSE = false;
            this.isMobile = false;
            this.isTablet = false;
            //this.isDesktop = false;
            this.isDesktopWide = false;

            this.isDesktop = true;
            this.$window.trigger(this.events.Change);
            this.$window.trigger(this.events.Desktop);
        }
    }else if(window.matchMedia( this.DesktopWideQuery ).matches){
        if(!this.isDesktopWide){
            this.isMobileSE = false;
            this.isMobile = false;
            this.isTablet = false;
            this.isDesktop = false;
            //this.isDesktopWide = false;

            this.isDesktopWide = true;
            this.$window.trigger(this.events.Change);
            this.$window.trigger(this.events.DesktopWide);
        }
    }
};