/**
 * dom の width を  css var の --width にセットし続ける(resize時)
 */

export function DomWidthWatch(selectors) {
    ajust(selectors);
    window.addEventListener('resize', (event) => {
        ajust(selectors);
    });
}

export function ajust(selectors){
    const ajust_ = (selector) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element, index) => {
            const width = element.offsetWidth;
            element.style.setProperty('--parent-width', width + 'px');
        });
    }

    if( Array.isArray(selectors) ){
        selectors.forEach((selector, index) => {
            ajust_(selector);
        });
    }else{
        ajust_(selectors);
    }
}